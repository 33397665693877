@font-face {
    font-family: 'ProductSans-Bold';
    src: url('/public/fonts/ProductSans-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'ProductSans-Regular';
    src: url('/public/fonts/ProductSans-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'ProductSans-Thin';
    src: url('/public/fonts/ProductSans-Thin.ttf') format('truetype');
  }
  
  @keyframes Gradient {
    0%, 100% {
      background-position: 96% 0%;
    }
    50% {

      background-position: 5% 100%;
    }
  }
  
  body {
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    background-size: 400% 400%;
    animation: Gradient 6s ease infinite;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .app-container {
    text-align: center;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 40px;
    width: 600px;
    transition: height 0.5s ease;
    
    
  }
  
  .deployment-item, .deployment-button {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: #666;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s;
  }
  
  .deployment-item:hover, .deployment-button:hover {
    transform: scale(1.02);
    background-color: #000000;
    color: white;
  }
  
  .deployment-button {
    border: none;
    cursor: pointer;
    text-align: center;
    margin: 20px 5px; /* Adjusted for increased space between buttons */
    font-family: 'ProductSans-Regular', sans-serif;
    
  }
  
  .login-card__header h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .login-footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .login-footer hr {
    margin: 20px 0;
  
  }
  
  .login-footer p {
    font-size: 14px;
    color: #666;
    font-family: 'ProductSans-Thin', sans-serif;
  }
  
  .blur {
    filter: blur(8px);
    pointer-events: none;
    user-select: none;
  }
  
  .content-blur {
    filter: blur(8px);
  }
  
  .login-card__header {
    display: flex;
    justify-content: center; /* Aligns children (logo) horizontally in the center */
    align-items: center; /* Aligns children (logo) vertically in the center */
    margin-bottom: 30px;
  }
  
  .deployment-heading {
    font-family: 'ProductSans-Bold', sans-serif; /* Fallback to sans-serif if custom font fails to load */
    letter-spacing: 0.4px;
    margin-top: 30px;
    
  }
  
  .history-heading
  {
    margin-top: 30px;
  }
  .deployment-option {
    font-family: 'ProductSans-Regular', sans-serif;
    color: #848484;
  }
  
  .login-footer p {
    font-family: 'ProductSans-Regular', sans-serif;
    color: #a7a7a7;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .logo-container, .star-rating-container {
    flex: 1;
  }
  
  .page-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1; /* Ensures the background is behind all other content */
  }
  
  .blob {
    position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    filter: blur(250px);
  }
  
  .top-right {
    top: -100px;
    right: -100px;
    background: radial-gradient(circle, #44f2eb 0%, #44f2eb 100%);
    animation: spin-right 7s linear infinite;
  }
  
  .bottom-left {
    bottom: -100px;
    left: -100px;
    background: radial-gradient(circle, #8e7bff 0%, #8e7bff 100%);
    animation: spin-left 6s linear infinite;
  }
  
  @keyframes spin-right {
    0%, 100% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(90deg) scale(1.2);
    }
    50% {
      transform: rotate(180deg) scale(1);
    }
    75% {
      transform: rotate(270deg) scale(0.8);
    }
  }
  
  @keyframes spin-left {
    0%, 100% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(-90deg) scale(1.2);
    }
    50% {
      transform: rotate(-180deg) scale(1);
    }
    75% {
      transform: rotate(-270deg) scale(0.8);
    }
  }
  
  /* Example class names, adjust according to your actual CSS */
  .deployment-button strong {
    font-family: 'ProductSans-Bold';
  }
  
  .deployment-button span {
    font-family: 'ProductSans-Regular';
  }

  .logout-button-container {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    /* Add more styling as needed */
  }


/* */

/* Container for the tab buttons */
.tab-container {
  display: inline-flex;
  
  width: 80%;
  background-color: #ececec; /* Grey background that matches the non-active tabs */
  border-radius: 10px; /* Slightly rounded corners for the container */
  border: 2px solid #dddddd;
  overflow: hidden; /* This will ensure the child elements adhere to the container's border radius */
}

/* General styling for tab buttons */
.tab-button {
  flex-grow: 1; /* Allows the buttons to grow and take up equal space */
  padding: 10px 20px;
  border: none; /* Removes the border */
  background-color: #ececec; /* Grey background for non-active tabs */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-size: 14px;
  font-weight: bold;
  color: #808080; /* Text color for the tabs */
  text-align: center;
  border-radius: 8px;
  margin: 1px;
}

/* Styling for the active tab button */
.tab-button.active {
  background-color: #1a1a1a; /* White background for the active tab */
  border-radius: 7px;
 color: #ffffff;
}

/* Hover effect for non-active tabs */
.tab-button:not(.active):hover {
  background-color: #ffffff; /* White background on hover */
  
}

/* Adjustments to the hover effect */
.tab-button:not(.active):hover {
  background-color: #ffffff;
}

/* You can add additional specific styles as needed to match your app's design */

.sample-content {
  background-color: #f9f9f9; /* Light grey background for contrast */
  border-left: 5px solid #000000; /* Blue left border for a touch of color */
  padding: 15px; /* Adds some space inside the borders */
  margin: 10px 0; /* Adds space above and below the element */
  font-family: Arial, sans-serif; /* Sets a clean, readable font */
  font-size: 0.9rem; /* Sets a slightly smaller font size for this content */
  color: #333; /* Darker text for readability */
  white-space: pre-wrap; /* Ensures that the formatting (new lines, spaces) is preserved */
}

.logout-button
{
  border: none;
    cursor: pointer;
    text-align: center;
    width: 100px;
    height: 30px;
    font-family: 'ProductSans-Regular', sans-serif;
    border-radius: 3px;
}

.logout-button:hover
{
  border: none;
  background-color: #000000;
  color: #f9f9f9;
    cursor: pointer;
    text-align: center;
   
    font-family: 'ProductSans-Regular', sans-serif;
    
    border-radius: 3px;
}
.help
{
  margin-top: 30px;
 
}

.help {
  color: rgb(66, 66, 66); /* Sets the color of the text within the .help class to white */
}

.help a {
  color: rgb(101, 9, 163); /* Sets the color of links within the .help class to cyan */
  text-decoration: none; /* Optional: Removes the underline from the link */
}

.help a:hover {
  text-decoration: underline; /* Optional: Adds underline to the link on hover for better UX */
}


.about
{
  font-family: 'ProductSans-Regular', sans-serif;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  margin: 0 10px;
  font-size: 24px; /* Adjust the size of the icons */
  color: #333; /* Icon color */
}

.social-icons a:hover {
  color: #6e00c9; /* Icon hover color */
}


/* Advanced Deployment Form Styles */

/* Styling for form groups (label + input/select/textarea) */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

/* Input fields and select boxes */
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  color: #333;
}

/* Adjust the focus state for input fields, select boxes, and textareas */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Textarea specific styling */
.form-group textarea {
  height: 100px; /* Adjust based on your needs */
  resize: vertical; /* Allows vertical resizing, feel free to adjust */
}

/* Styling for the form submission button */
.form-submit-button {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block; /* Allows setting dimensions */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.form-submit-button:hover {
  background-color: #0056b3; /* Darker shade for hover state */
}

/* Optional: Styling for a secondary action or cancel button */
.form-cancel-button {
  background-color: #6c757d; /* Gray color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.2s;
  margin-left: 10px; /* Adds some space between this and the submit button */
}

.form-cancel-button:hover {
  background-color: #545b62; /* Darker shade for hover state */
}
