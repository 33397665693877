@font-face {
  font-family: 'ProductSans-Bold';
  src: url('/public/fonts/ProductSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProductSans-Regular';
  src: url('/public/fonts/ProductSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProductSans-Thin';
  src: url('/public/fonts/ProductSans-Thin.ttf') format('truetype');
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: saturate(180%) blur(50px);
  backdrop-filter: saturate(180%) blur(50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button, .close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.modal-body {
  margin-top: 20px;
}

/* New minimal button style */
.minimal-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 0 auto; /* Center the button horizontally */
  display: block; /* Makes the button a block element to enable margin auto */

}

.minimal-button:hover {
  background-color: #e0e0e0;
}

.modal-footer {
  text-align: center; /* Centers the button text if needed */
  padding: 20px; /* Adds some padding around the footer content */
}

.minimal-button:hover {
  background-color: #000000;
  color: #ffffff;
}
/* Apply ProductSans-Bold to modal titles and deploy button */
.modal-title, .minimal-button {
  font-family: 'ProductSans-Bold';
}

/* Assuming you want the modal content to use ProductSans-Regular by default */
.modal-body {
  font-family: 'ProductSans-Regular';
}

.modal-action-button
{
  border: none;
  cursor: pointer;
  text-align: center;
  padding:15px;
  font-family: 'ProductSans-Regular', sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s;
}

.modal-action-button:hover
{
  border: none;
  background-color: #000000;
  color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    font-family: 'ProductSans-Regular', sans-serif;
    border-radius: 5px;
}

.modal-secondary-action-button
{
  border: none;
  cursor: pointer;
  text-align: center;
  padding:15px;
  
  font-family: 'ProductSans-Regular', sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s;
}

.modal-secondary-action-button:hover
{
  border: none;
  background-color: #000000;
  color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    font-family: 'ProductSans-Regular', sans-serif;
    border-radius: 5px;
}