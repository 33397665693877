@font-face {
    font-family: 'ProductSans-Bold';
    src: url('/public/fonts/ProductSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'ProductSans-Regular';
    src: url('/public/fonts/ProductSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'ProductSans-Thin';
    src: url('/public/fonts/ProductSans-Thin.ttf') format('truetype');
}

body {
    background: #121212 !important; /* Dark background */
   
    font-family: 'ProductSans-Regular', sans-serif;
    overflow-x: hidden;
}

[data-amplify-authenticator] {
    font-family: 'ProductSans-Regular', sans-serif;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 20px;
    background: #1e1e1e; /* Dark surface */
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border: 2px solid #333; /* Subtle border */
    transition: box-shadow 0.3s ease;
    color: white;
}

.amplify-tabs__list {
    display: flex;
}

.amplify-flex {
    border-radius: 10px;
    border: none; /* Borders hidden */
}

.amplify-field-group__control {
    background: #333; /* Darker input fields */
    margin-top: 10px;
    color: grey;
}

.amplify-tabs__item {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    color: white;
    cursor: pointer;
    background-color: #1e1e1e; /* Darker tabs */
    border: none;
    outline: none;
    margin: 5px;
    font-family: 'ProductSans-Regular', sans-serif;
}

.amplify-tabs__item--active, .amplify-tabs__item:hover {
    background-color: #222; /* Darker hover state */
    border-radius: 5px;
    font-family: 'ProductSans-Bold', sans-serif;
}

.amplify-tabs__panel {
    padding: 20px 0;
}

.amplify-field-group__field-wrapper {
    margin-bottom: 15px;
}

.amplify-input, .amplify-button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: 'ProductSans-Regular', sans-serif;
    width: 300px; /* Retained width */
}

.amplify-textfield {
    color: white;
    margin-top: 30px;
    font-family: 'ProductSans-Regular', sans-serif;
}

.amplify-input {
    border: 1px solid #444; /* Darker border */
    border-radius: 4px;
    margin-top: 10px;
}

.amplify-button--primary {
    background-color: #6800c9; /* Retained button color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'ProductSans-Bold', sans-serif;
    margin: auto; /* Further ensures the button is centered */
    transition: background-color 0.3s ease; /* Smooth background transition */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.amplify-button--link {
    background: none;
    border: none;
    color: #9e9e9e; /* Lighter for visibility */
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: auto;
}

.amplify-button:hover, .amplify-button--primary:hover {
    opacity: 0.9;
}

.amplify-field__show-password {
    position: absolute;
    visibility: hidden;
}

.amplify-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.amplify-alert__body, .amplify-text, .amplify-text--error {
    color: white;
    font-family: 'ProductSans-Regular', sans-serif;
}

.blob {
    position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    filter: blur(250px);
}

.top-right, .bottom-left {
    background: radial-gradient(circle, #bb86fc 0%, #bb86fc 100%);
}


.amplify-button:hover,
.amplify-tabs__item--active,
.amplify-tabs__item:hover,
.amplify-button--primary:hover {
    opacity: 0.9;
    transition: opacity 0.3s ease, background-color 0.3s ease; /* Smooth transition for opacity and background-color */
}

/* Base state with transition */
.amplify-button,
.amplify-tabs__item {
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; /* Smooth transition for hover in and hover out */
}

/* Hover state */
.amplify-button:hover,
.amplify-tabs__item:hover {
    opacity: 0.9; /* Example hover effect */
    /* No need to repeat the transition property here since it's already defined in the base state */
}

/* Active state for tabs */
.amplify-tabs__item--active {
    background-color: #222; /* Example active state */
    /* Transition applies because it's defined in the base state */
}


  